<template>
  <div>
    <v-select
        :filterable="false"
        :options="options"
        @search="onSearch"
        :getOptionLabel="optionLabel"
        v-model="selectedSet"
        @option:selected="updateSelected"
    >
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          No results found for <em>{{ search }}</em>
        </template>
        <template v-else>Start typing to search sets...</template>
      </template>
      <template slot="no-options">
        Start typing to search sets...
      </template>
      <template slot="option" slot-scope="option">
        <div>
          {{ option.attributes.name }}
        </div>
      </template>
    </v-select>
    <input :name="name" :value="selectedSetId" type="hidden" />
  </div>
</template>

<script>
import setApi from '../api/set.api';
import 'vue-select/dist/vue-select.css';
import _ from 'lodash';

export default {
  name: 'SetSelect.vue',
  props: {
    selected: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: 'set_id',
    },
  },
  data() {
    return {
      options: [],
      selectedSet: null,
    }
  },
  async created() {
    if (this.selected) {
      this.selectedSet = (await setApi.getSet(this.selected)).data;
    }
  },
  computed: {
    selectedSetId() {
      if (!this.selectedSet) {
        return "";
      }
      return this.selectedSet.attributes.id;
    },
  },
  methods: {
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      setApi.searchSets(
          {name: `like:${escape(search)}`}
      ).then(res => {
        vm.options = res.data;
        loading(false);
      });
    }, 350),
    optionLabel(option) {
      if (option.hasOwnProperty('attributes')) {
        return option.attributes.name;
      }
    },
    updateSelected(selectedOption) {
      this.selectedSet = selectedOption;
    },
  },
}
</script>

<style scoped>

</style>