<template>
  <div class="card">
    <div class="card-header">
      Actions
      <div class="float-right">
        <div class="card-header-action collapse-action" @click="collapse">
          <span class="fa fa-minus" v-if="showContent" title="Collapse"></span>
          <span class="fa fa-plus" v-else title="Expand"></span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="showContent">
      <ul class="list-unstyled">
        <li>
          <a :href="route('admin.sets.edit', [this.setId])">
            <span class="fa fa-edit"></span> Edit set
          </a>
        </li>
        <li>
          <delete-confirmation
              :object-id="setId"
              title="Delete set"
              type="sets"
              @objectDeleted="setDeleted($event)"
          >
            Delete set
          </delete-confirmation>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <a :href="route('admin.cards.create', {set_id: this.setId, redirect: route('admin.sets.show', {set: this.setId})})" title="Add card to set">
            <span class="fa fa-plus"></span>
            Add card to checklist
          </a>
        </li>
        <li>
          <set-checklist-modal
              :set-id="this.setId"
              :show-modal="false"
          >
            Bulk add cards to checklist
          </set-checklist-modal>
        </li>
        <!--<li>
          <a :href="route('admin.sets.create', {parent: this.setId, parallel: 1})">
            <span class="fa fa-clone"></span>
            Create parallel set
          </a>
        </li>-->
        <li v-if="!parentId">
          <a :href="route('admin.sets.create', {parent: this.setId})">
            <span class="fa fa-project-diagram"></span>
            Create new subset
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetActions',
  props: {
    setId: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showContent: true,
    };
  },
  methods: {
    collapse() {
      this.showContent = !this.showContent
    },
    setDeleted() {
      // if the set has a parent, take them there
      if (this.parentId) {
        window.location.href = '/sets/' + this.parentId
      } else {
        window.location.href = '/sets'
      }
    },
  },
}
</script>

<style scoped>
.card-body ul li {
  padding: 2px 0;
}
</style>