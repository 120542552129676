<template>
  <div class="card">
    <div class="card-header">
      Publish
      <div class="float-right">
        <div class="card-header-action collapse-action" @click="collapse">
          <span class="fa fa-minus" v-if="showContent" title="Collapse"></span>
          <span class="fa fa-plus" v-else title="Expand"></span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="showContent">
      <div>
        <span class="fa fa-thumbtack"></span>
        <span>Status: {{this.status}}</span>
      </div>
      <button type="submit" class="btn btn-primary" id="btn-publish" @click="updateStatus('published')" :disabled="updating" v-if="this.status !== 'published'">
        <span class="fa fa-spinner fa-pulse" v-if="updating"></span>
        Publish
      </button>
      <button type="submit" class="btn btn-primary" id="btn-draft" @click="updateStatus('draft')" :disabled="updating" v-if="this.status === 'published'">
        <span class="fa fa-spinner fa-pulse" v-if="updating"></span>
        Revert to Draft
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Publish',
  props: {
    setId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      updating: false,
      showContent: true,
    };
  },
  methods: {
    collapse() {
      this.showContent = !this.showContent
    },
    updateStatus(status) {
      this.updating = true

      const data = {
        "status": status
      };

      const url = '/api/sets/' + this.setId
      fetch(url, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else{
          console.log("Server returned " + response.status + " : " + response.statusText)
        }
      })
      .then(response => {
        this.result = response.body
        this.responseAvailable = true
      })
      .catch(err => {
        console.log(err)
      })

      this.updating = false
      this.status = status
    },
  },
}
</script>

<style lang="scss" scoped>
#btn-publish,
#btn-draft {
  width: 100%;
  margin-top: 20px;
}
.action-link {
  color: #3490dc;
  cursor: pointer;
  font-size: 90%;
}
</style>