<template>
  <div class="card">
    <div class="card-header">
      Subsets ({{ count }})
      <div class="float-right">
        <div class="card-header-action collapse-action" @click="collapse">
          <span class="fa fa-minus" v-if="showContent" title="Collapse"></span>
          <span class="fa fa-plus" v-else title="Expand"></span>
        </div>
      </div>
    </div>
    <div class="card-body" id="subsets" v-if="showContent">
      <template v-for="(subsets, type) in this.subs">
        <h4 v-show="type !== 'Uncategorized'">{{ type }}</h4>
        <ul class="subsets list-unstyled container">
          <li v-for="subset in subsets" :key="subset.id" class="row">
            <a :href="route('admin.sets.show', [subset.id])" class="col-6">{{ subset.name }}</a>
            <span class="col">{{ subset.current_card_count }} / {{ subset.card_count }}</span>
            <span class="col">{{ subset.status }}</span>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetChildren',
  props: {
    set: {
      type: Object,
      required: true,
    },
    subs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showContent: true,
      lsKey: '',
    };
  },
  created() {
    this.lsKey = 'set_' + this.set.id + '_children'

    if (localStorage[this.lsKey]) {
      this.showContent = localStorage[this.lsKey]
      if (this.showContent === "false") {
        this.showContent = false
      }
    } else {
      this.showContent = true
    }
  },
  computed: {
    count() {
      return this.set.subsets.length
    }
  },
  methods: {
    collapse() {
      this.showContent = !this.showContent
      localStorage[this.lsKey] = this.showContent
    },
  },
}
</script>

<style lang="scss" scoped>
.subsets {
  clear: both;

  li {
    padding: 5px;
    color: #888;
  }
  li:nth-of-type(even) {
    background-color: #efefef;
  }
}
</style>
