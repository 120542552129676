import Vue from 'vue'
import Vuex from 'vuex'
import checklist from './checklist.store';
import set from './set.store'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        checklist,
        set,
    },
})
