<template>
  <div class="card">
    <div class="card-header">
      Maintenance
      <div class="float-right">
        <div class="card-header-action collapse-action" @click="collapse">
          <span class="fa fa-minus" v-if="showContent" title="Collapse"></span>
          <span class="fa fa-plus" v-else title="Expand"></span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="showContent">
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerMaintenance',
  data() {
    return {
      showContent: true,
    };
  },
  methods: {
    collapse() {
      this.showContent = !this.showContent
    },
  },
}
</script>

<style scoped>

</style>