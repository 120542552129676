import { render, staticRenderFns } from "./SetFormTitle.vue?vue&type=template&id=12b63402&scoped=true&"
import script from "./SetFormTitle.vue?vue&type=script&lang=js&"
export * from "./SetFormTitle.vue?vue&type=script&lang=js&"
import style0 from "./SetFormTitle.vue?vue&type=style&index=0&id=12b63402&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12b63402",
  null
  
)

export default component.exports