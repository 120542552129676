<template>
  <div>
    <div class="form-group">
      <label for="input-title">Title</label>
      <input type="text" class="form-control" id="input-title" name="title" value="" v-model="title" />
    </div>
    <div id="name-override">
      <input type="checkbox" id="input-name-override" name="name_override" :checked="nameOverride" value="1" />
      <label for="input-name-override">Override default naming standard</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetFormTitle",
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    nameOverride: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped>
#name-override {
  float: right;
  margin-top: -12px;
}
</style>