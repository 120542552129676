<template>
  <div>
    <a @click="show=true" :title="title" class="delete-action">
      <span class="fa fa-trash-alt"></span>
      <slot></slot>
    </a>

    <b-modal
        :id="deleteId"
        title="Confirm Delete"
        v-model="show"
        @ok="handleOk"
    >
      <p>Are you sure you want to delete {{ this.description }}?</p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'DeleteConfirmation',
  props: {
    objectId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Delete this object',
    },
    description: {
      type: String,
      required: false,
      default: 'this object',
    },
  },
  data: function () {
    return {
      show: false,
    }
  },
  computed: {
    deleteId() {
      return 'delete-' + this.objectId
    },
  },
  methods: {
    handleOk: function(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      this.deleteObject()

      // close modal
      this.$nextTick(() => {
        this.$bvModal.hide(this.deleteId)
      })
    },
    deleteObject() {
      const url = '/api/' + this.type + '/' + this.objectId
      fetch(url, {
        method: 'DELETE',
      })
      .then(response => {
        if (response.ok) {
          this.$emit('objectDeleted', this.objectId)
          return true
        } else{
          console.log("Server returned " + response.status + " : " + response.statusText)
        }
      })
    },
  },
}
</script>

<style scoped>
.delete-action {
  color: #FF4433;
  cursor: pointer;
}
</style>
