<template>
  <div class="card">
    <div class="card-header" v-if="this.displayHeader === true">
      <div class="float-left">
        <span v-if="cardCount">Checklist ( {{ currentCardCount }} / {{ cardCount }} )</span>
        <span v-else>Checklist</span>
      </div>
      <div class="float-right">
        <div class="card-header-action collapse-action" @click="collapse">
          <span class="fa fa-minus" v-if="showContent" title="Collapse"></span>
          <span class="fa fa-plus" v-else title="Expand"></span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="showContent">
      <div v-if="this.displayHeader === false">
        {{ currentCardCount }} / {{ cardCount }}
      </div>
      <template v-if="currentCardCount">
        <ul class="checklist list-unstyled">
          <li
              v-for="(card, index) in checklist"
              :key="card.id"
              @mouseover="show = index"
              @mouseout="show = false"
          >
            <input type="checkbox" name="card-cb" :id="card.id" v-if="editMode">

            <a :href="route('admin.cards.show', [card.id])" v-if="!editMode">{{ card.name }}</a>
            <label :for="card.id" v-else>{{ card.name }}</label>

            <span class="badge badge-pill badge-info">{{ card.section }}</span>
            <div class="actions float-right" v-show="show === index" v-if="!editMode">
              <div class="action float-left">
                <a :href="route('admin.cards.edit', {card: card.id, mode: 'checklist'})" title="Checklist edit mode">
                  <span class="fa fa-tasks"></span>
                </a>
              </div>
              <div class="action float-left">
                <delete-confirmation
                    :object-id="card.id"
                    :description="card.name"
                    title="Delete card"
                    type="cards"
                    @objectDeleted="cardDeleted($event)"
                >
                </delete-confirmation>
              </div>
              <div class="action float-left">
                <a :href="route('admin.cards.edit', [card.id])" title="Edit card">
                  <span class="fa fa-edit"></span>
                </a>
              </div>
              <div class="action float-left">
                <a :href="route('admin.cards.show', [card.id])" title="View card">
                  <span class="fa fa-ellipsis-h"></span>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </template>
      <p v-else>No cards have been added to the checklist.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetChecklist',
  props: {
    set: {
      type: Object,
      required: true,
      default: '',
    },
    displayHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      showContent: true,
    };
  },
  created() {
    this.$store.dispatch('checklist/setChecklist', this.set.checklist)
  },
  /*mounted() {
    let ele = document.getElementsByName('card-cb');
    for(let i=0; i<ele.length; i++){
      ele[i].checked=true;
    }
  },*/
  computed: {
    checklist() {
      return this.$store.getters["checklist/checklist"]
    },
    editMode() {
      return this.$store.getters["checklist/editMode"]
    },
    cardCount() {
      return this.set.card_count
    },
    currentCardCount() {
      if (typeof this.checklist === 'undefined') {
        return 0
      }
      return this.checklist.length
    },
    id() {
      return this.set.id
    },
  },
  methods: {
    cardDeleted(cardId) {
      this.$store.dispatch('checklist/removeCard', cardId)
    },
    collapse() {
      this.showContent = !this.showContent
    },
  },
}
</script>

<style lang="scss" scoped>
.checklist {
  clear: both;

  li {
    padding: 5px;
  }
  li:nth-of-type(even) {
    background-color: #efefef;
  }
  .actions {
    a {
      padding: 0 3px;
    }
    .action {
      padding: 0 3px;
    }
  }
}
</style>
