import axios from 'axios';

function initialState() {
    return {

    }
}

const set = {
    namespaced: true,
    state: initialState,
    mutations: {

    },
    actions: {
        createSet({ commit }, attributes) {
            const payload = {
                data: {
                    type: 'sets',
                    attributes: attributes
                }
            }
            axios.post('/api/sets', payload)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else{
                        console.log("Server returned " + response.status + " : " + response.statusText)
                    }
                })
                .then(response => {
                    this.result = response.body
                    this.responseAvailable = true
                })
                .catch(err => {
                    console.log(err)
                })
        },
    },
}

export default set
