<template>
  <div class="card">
    <div class="card-header">
      Actions
      <div class="float-right">
        <div class="card-header-action collapse-action" @click="collapse">
          <span class="fa fa-minus" v-if="showContent" title="Collapse"></span>
          <span class="fa fa-plus" v-else title="Expand"></span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="showContent">
      <ul class="list-unstyled">
        <li>
          <a :href="route('admin.cards.edit', {card: cardId, mode: 'card'})">
            <span class="fa fa-cog"></span> Edit Card
          </a>
        </li>
        <li>
          <delete-confirmation
              :object-id="cardId"
              title="Delete card"
              type="cards"
              @objectDeleted="cardDeleted($event)"
          >
            Delete Card
          </delete-confirmation>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <a :href="route('admin.cards.create', {parent: this.cardId})">
            <span class="fa fa-project-diagram"></span>
            Create new child card
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardActions',
  props: {
    cardId: {
      type: String,
      required: true,
    },
    setId: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      showContent: true,
    };
  },
  methods: {
    cardDeleted(cardId) {
      this.$store.dispatch('checklist/removeCard', cardId)
      let href = '/sets/'
      if (this.setId) {
        href += this.setId
      }
      window.location.href = href
    },
    collapse() {
      this.showContent = !this.showContent
    },
  },
}
</script>

<style scoped>
.card-body ul li {
  padding: 2px 0;
}
</style>