<template>
  <div>
    <v-select
        :filterable="false"
        :options="options"
        @search="onSearch"
        :getOptionLabel="optionLabel"
        v-model="selectedCard"
        @option:selected="updateSelected"
    >
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          No results found for <em>{{ search }}</em>
        </template>
        <template v-else>Search card by entering UUID ...</template>
      </template>
      <template slot="no-options">
        Search card by entering UUID ...
      </template>
      <template slot="option" slot-scope="option">
        <div>
          {{ option.attributes.name }}
        </div>
      </template>
    </v-select>
    <input name="parent_id" :value="selectedCardId" type="hidden" />
  </div>
</template>

<script>
import cardApi from '../api/card.api';
import 'vue-select/dist/vue-select.css';
import _ from 'lodash';

export default {
  name: 'CardSelect.vue',
  props: {
    selected: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      options: [],
      selectedCard: null,
    }
  },
  async created() {
    if (this.selected) {
      this.selectedCard = (await cardApi.getCard(this.selected)).data;
    }
  },
  computed: {
    selectedCardId() {
      if (!this.selectedCard) {
        return "";
      }
      return this.selectedCard.attributes.id;
    },
  },
  methods: {
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      cardApi.getCard(search).then(res => {
        let results = [];
        results.push(res.data);
        vm.options = results;
        loading(false);
      });
    }, 350),
    optionLabel(option) {
      if (option.hasOwnProperty('attributes')) {
        return option.attributes.name;
      }
    },
    updateSelected(selectedOption) {
      this.selectedCard = selectedOption;
    },
  },
}
</script>

<style scoped>

</style>