<template>
  <div class="card">
    <div class="card-header">
      Editor
      <div class="float-right">
        <div class="card-header-action collapse-action" @click="collapse">
          <span class="fa fa-minus" v-if="showContent" title="Collapse"></span>
          <span class="fa fa-plus" v-else title="Expand"></span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="showContent">
      <div>
        <b-form-checkbox @change="setEditMode" switch id="edit-toggler">
          Edit Checklist
        </b-form-checkbox>
      </div>
      <div v-if="editMode">
        <a @click="toggleSelectAll">Select All</a> | <a @click="toggleDeselectAll">Deselect All</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChecklistEditor',
  data() {
    return {
      editMode: false,
      showContent: true,
      selectAll: false,
      deselectAll: false,
    };
  },
  methods: {
    collapse() {
      this.showContent = !this.showContent
    },
    setEditMode() {
      this.editMode = !this.editMode
      this.$store.dispatch('checklist/setEditMode', this.editMode)
    },
    toggleSelectAll() {
      this.selectAll = !this.selectAll
      this.$store.dispatch('checklist/setSelectAll', this.selectAll)
    },
    toggleDeselectAll() {
      this.deselectAll = !this.deselectAll
      this.$store.dispatch('checklist/setDeselectAll', this.deselectAll)
    },
  },
}
</script>

<style scoped>

</style>