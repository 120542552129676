<template>
  <div class="card">
    <div class="card-header" v-if="this.displayHeader === true">
      Details
      <div class="float-right">
        <div class="card-header-action collapse-action" @click="collapse">
          <span class="fa fa-minus" v-if="showContent" title="Collapse"></span>
          <span class="fa fa-plus" v-else title="Expand"></span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="showContent">
      <div id="set-id">ID: {{ this.set.id }}</div>
      <div v-if="this.set.description">{{ this.set.description }}</div>
      <div v-if="this.set.card_count">Card count: {{ this.set.card_count }}</div>
      <div v-if="this.set.serial">Serial Numbered To: {{ this.set.serial }}</div>
      <div v-if="this.set.parent_set">Base Set: <a :href="route('admin.sets.show', [this.set.parent_set])">{{ this.set.parentset[0].name }}</a></div>
      <div v-if="this.set.genre_id && this.set.genres">Genre: <a :href="route('admin.genres.show', [this.set.genre_id])">{{ this.set.genres[0].name }}</a></div>
      <div v-if="this.set.manufacturer_id && this.set.manufacturers">Manufacturer: <a :href="route('admin.manufacturers.show', [this.set.manufacturer_id])">{{ this.set.manufacturers[0].name }}</a></div>
      <div v-if="this.set.brand_id && this.set.brands">Brand: <a :href="route('admin.brands.show', [this.set.brand_id])">{{ this.set.brands[0].name }}</a></div>
      <div v-if="this.set.year_id && this.set.years">Year: <a :href="route('admin.years.show', [this.set.year_id])">{{ this.set.years[0].name }}</a></div>
      <div>Parallel: {{ (this.set.is_parallel) ? 'Yes' : 'No' }}</div>
      <div>Insert: {{ (this.set.is_insert) ? 'Yes' : 'No' }}</div>
      <div>Autograph: {{ (this.set.is_autograph) ? 'Yes' : 'No' }}</div>
      <div>Relic: {{ (this.set.is_relic) ? 'Yes' : 'No' }}</div>
      <div v-if="this.set.number_prefix">Number Prefix: {{ this.set.number_prefix }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetDetails',
  props: {
    set: {
      type: Object,
      required: true,
      default: '',
    },
    displayHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      showContent: true,
    };
  },
  methods: {
    collapse() {
      this.showContent = !this.showContent
    },
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  div {
    padding: 2px 0;
  }
}
</style>