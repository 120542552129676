function initialState() {
    return {
        editMode: false,
        checklist: [],
        deselectAll: false,
        selectAll: false,
    }
}

const checklist = {
    namespaced: true,
    state: initialState,
    getters: {
        checklist(state) {
            return state.checklist
        },
        editMode(state) {
            return state.editMode
        },
        selectAll(state) {
            return state.selectAll
        },
        deselectAll(state) {
            return state.deselectAll
        },
    },
    mutations: {
        checklist(state, value) {
            state.checklist = value
        },
        editMode(state, value) {
            state.editMode = value
        },
        selectAll(state, value) {
            state.deselectAll = false
            state.selectAll = value
        },
        deselectAll(state, value) {
            state.selectAll = false
            state.deselectAll = value
        },
    },
    actions: {
        async removeCard({ commit, state }, cardId) {
            commit('checklist', state.checklist.filter((itm) => itm.id !== cardId))
        },
        setEditMode({ commit }, value) {
            commit('editMode', value)
        },
        setChecklist({ commit }, value) {
            commit('checklist', value)
        },
        setSelectAll({ commit }, value) {
            commit('selectAll', value)
        },
        setDeselectAll({ commit }, value) {
            commit('deselectAll', value)
        },
    },
}

export default checklist
