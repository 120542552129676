<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form method="post" action="" class="form-horizontal">
            <div class="form-group">
              <label for="input-set">Name</label>
              <input type="text" class="form-control" id="input-set" name="name" value="" disabled="disabled" />
              <span class="description">The name of the set is derived from the attributes of the set.</span>
            </div>
            <div class="form-group">
              <label for="input-title">Title</label>
              <input type="text" class="form-control" id="input-title" name="title" value="" v-model="title" />
            </div>
            <div class="form-group">
              <label for="input-description">Description</label>
              <textarea class="form-control" id="input-description" rows="5" name="description" v-model="description"></textarea>
            </div>
            <div class="form-group">
              <label for="input-parent">Parent Set</label>
              <select name="parent_id" id="input-parent" class="form-control">
                <option value="">-- SELECT PARENT SET --</option>
              </select>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label for="input-genre">Genre</label>
                  <select name="genre_id" id="input-genre" class="form-control">
                    <option value="0">-- SELECT GENRE --</option>
                  </select>
                </div>

                <div class="col-sm-6">
                  <label for="input-year">Year</label>
                  <select name="year_id" id="input-year" class="form-control">
                    <option value="0">-- SELECT YEAR --</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label for="input-manufacturer">Manufacturer</label>
                  <select name="manufacturer_id" id="input-manufacturer" class="form-control">
                    <option value="0">-- SELECT MANUFACTURER --</option>
                  </select>
                </div>

                <div class="col-sm-6">
                  <label for="input-brand">Brand</label>
                  <select name="brand_id" id="input-brand" class="form-control">
                    <option value="0">-- SELECT BRAND --</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label for="input-card-count">Card Count</label>
                  <input type="text" class="form-control" id="input-card-count" name="card_count" value="" v-model="cardCount" />
                </div>

                <div class="col-sm-6">
                  <label for="input-serial-count" style="padding-top: 0; margin-top: -3px;">Serial Numbered To</label>
                  <input type="text" class="form-control" id="input-serial-count" name="serial" value="" v-model="serial" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label for="input-number-prefix">Number Prefix</label>
                  <input type="text" class="form-control" id="input-number-prefix" name="number_prefix" value="" v-model="numberPrefix" />
                </div>

                <div class="col-sm-6">
                  <label>Attributes</label>
                  <div class="row">
                    <div class="col-sm-6">
                      <input type="checkbox" id="input-parallel" name="is_parallel" value="1" v-model="isParallel" />
                      <label for="input-parallel">Parallel Set</label>
                    </div>
                    <div class="col-sm-6">
                      <input type="checkbox" id="input-insert" name="is_insert" value="1" v-model="isInsert" />
                      <label for="input-insert">Insert Set</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-12">
                <div class="container">
                  <div class="row justify-content-md-center">
                    <div class="col-sm-3 col-sm-offset-3">
                      <b-button
                          variant="primary"
                          class="float-right"
                          @click="createSet"
                      >
                        Create set
                      </b-button>
                    </div>
                    <div class="col-sm-3">
                      <a href="" class="btn btn-outline-secondary float-left" role="button" aria-pressed="true">Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SetChecklistModal from "./SetChecklistModal";

export default {
  name: 'SetForm',
  data: function () {
    return {
      title: null,
      description: null,
      cardCount: null,
      serial: null,
      numberPrefix: null,
      isParallel: false,
      isInsert: false,
    }
  },
  methods: {
    async createSet() {
      const attributes = {
        title: this.title,
        description: this.description,
        card_count: this.cardCount,
        serial: this.serial,
        number_prefix: this.numberPrefix,
        is_parallel: this.isParallel,
        is_insert: this.isInsert,
      }
      const set = await this.$store.dispatch('set/createSet', attributes)
      console.log(set)

      /*let ComponentClass = Vue.extend(SetChecklistModal)
      let instance = new ComponentClass({
        propsData: { type: 'primary' }
      })
      instance.$mount()*/
    },
  },
}
</script>

<style scoped>

</style>
