<template>
  <div :class="classes" :id="`rate-${objectType}`" role="tabpanel" :aria-labelledby="`${objectType}-tab`">
    {{ objectType }}
  </div>
</template>

<script>
export default {
  props: {
    objectType: {
      type: String,
      required: true,
    },
    defaultSelection: {
      type: Boolean,
      required: false,
    },
  },
  mounted() {
    console.log('Chart component mounted.')
  },
  computed: {
    classes() {
      let theClasses = 'tab-pane fade card-body'
      if (this.defaultSelection) {
        theClasses += ' show active'
      }
      return theClasses
    }
  },
}
</script>
