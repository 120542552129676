import axios from 'axios';

const cardApi = {
    searchCards: async (filters) => {
        let url = '/api/cards';

        const search = Object.keys(filters).map(function(key) {
            return key + '=' + filters[key];
        }).join('&');
        if (search) {
            url += '?' + search;
        }

        return await axios.get(url);
    },
    getCard: async (id) => {
        let url = `/api/cards/${id}?include=`;
        return await axios.get(url);
    },
}

export default cardApi;