<template>
  <div>
    <a href="#" v-b-modal.add-checklist-modal title="Bulk add cards to checklist">
      <span class="fa fa-list"></span>
      <slot></slot>
    </a>

    <b-modal
        id="add-checklist-modal"
        title="Add Checklist"
        hide-header-close
        @ok="handleOk"
        @show="resetInitialModal"
        @hidden="resetInitialModal"
        ref="add-checklist-modal"
    >
      <p>Select an option below:</p>
      <b-alert :show="error === true" variant="danger">Select one option before pressing the OK button.</b-alert>
      <b-list-group ref="list">
        <b-list-group-item
            ref="none"
            href="#"
            @click="selectItem('none')"
            :active="selectedItem === 'none'"
        >
          <span class="h5">
            <b-icon icon="square" />
            Bypass adding cards to this set
          </span>
          <span class="float-right">
            <b-icon icon="info-circle" id="item-none" />
            <b-popover target="item-none" triggers="click" placement="top">
              No cards will be added when using this option. Cards would have to manually be added to the set.
            </b-popover>
          </span>
        </b-list-group-item>
        <b-list-group-item
            href="#"
            ref="empty"
            @click="selectItem('empty')"
            :active="selectedItem === 'empty'"
        >
          <span class="h5">
            <b-icon icon="check-square" />
            Add empty cards to this set
          </span>
          <span class="float-right">
            <b-icon icon="info-circle" id="item-empty" />
            <b-popover target="item-empty" triggers="click" placement="top">
              Cards will be added to the set based on the card count defined for the set. No data will be
              added to the cards except for a number. If cards have already been added to the set those cards
              will not be affected. However, if any cards are missing, an attempt to add those cards will be made.
            </b-popover>
          </span>
        </b-list-group-item>
        <b-list-group-item
            href="#"
            ref="custom"
            @click="selectItem('custom')"
            :active="selectedItem === 'custom'"
        >
          <span class="h5">
            <b-icon icon="person-square" />
            Add cards to this set with details
          </span>
          <span class="float-right">
            <b-icon icon="info-circle" id="item-custom" />
            <b-popover target="item-custom" triggers="click" placement="top">
              You will be presented with a screen to add a checklist with the cards for this set.
            </b-popover>
          </span>
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <b-modal
        id="custom-checklist-modal"
        title="Add Checklist"
        @ok="handleChecklistOk"
        @show="resetChecklistModal"
        @hidden="resetChecklistModal"
        @shown="focusModal"
    >
      <p>Enter the checklist in the textarea below.</p>
      <p><code>#Name</code> to add a group of cards to a section. Each card below it will get that section until another section is found.</p>
      <b-form-textarea
          placeholder="Enter the checklist"
          v-model="checklistData"
          ref="checklistData"
      >
      </b-form-textarea>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    setId: {
      type: String,
      required: true,
      default: '',
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      selectedItem: '',
      error: false,
      checklistData: '',
    }
  },
  mounted() {
    if (this.showModal) {
      this.$refs['add-checklist-modal'].show()
    }
  },
  methods: {
    selectItem: function(item) {
      this.selectedItem = item
      this.error = false
    },
    resetInitialModal: function() {
      this.selectedItem = ''
      this.error = false
    },
    checkFormValidity: function() {
      let valid = false
      if (this.selectedItem !== '') {
        valid = true
      }
      return valid
    },
    handleOk: function(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      this.handleSubmit()
    },
    handleSubmit: function() {
      if (!this.checkFormValidity()) {
        this.error = true
        return
      }

      if (this.selectedItem === 'empty') {
        this.createEmptyChecklist()
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('add-checklist-modal')
      })

      if (this.selectedItem === 'custom') {
        this.$nextTick(() => {
          this.$bvModal.show('custom-checklist-modal')
        })
      }
    },
    createEmptyChecklist: function() {
      const url = '/api/sets/' + this.setId + '/add-missing-cards'
      fetch(url, {
        method: 'GET',
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else{
          console.log("Server returned " + response.status + " : " + response.statusText)
        }
      })
      .then(response => {
        this.result = response.body
        this.responseAvailable = true
      })
      .catch(err => {
        console.log(err)
      })
    },
    resetChecklistModal: function() {
      this.checklistData = ''
    },
    async handleChecklistOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      await this.submitChecklist()

      this.$nextTick(() => {
        this.$bvModal.hide('custom-checklist-modal')
      })
    },
    async submitChecklist() {
      const url = '/api/sets/' + this.setId + '/checklist'
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: this.checklistData,
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else{
          console.log("Server returned " + response.status + " : " + response.statusText)
        }
      })
      .then(response => {
        this.result = response.body
        this.responseAvailable = true
      })
      .catch(err => {
        console.log(err)
      })
    },
    focusModal() {
      this.$refs.checklistData.focus()
    },
  }
}
</script>

<style scoped>
textarea.form-control {
  height: 85%;
}
</style>