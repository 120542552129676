import axios from 'axios';

const setApi = {
    searchSets: async (filters) => {
        let url = '/api/sets';

        const search = Object.keys(filters).map(function(key) {
            return key + '=' + filters[key];
        }).join('&');
        if (search) {
            url += '?' + search;
        }

        return await axios.get(url);
    },
    getSet: async (id) => {
        let url = `/api/sets/${id}?include=`;
        return await axios.get(url);
    },
}

export default setApi;